export default {
    title: `中文`,
    global: {
    },
    header: {
        product: `首页`,
        price: `定价及付款`,
        about: `关于我们`,
        help: `帮助中心`,
        language: `语言`,
        zh: `中文`,
        en: `English`,
        login: `登录`,
        changeLanguage: `中英文切换`,
    },
    //关于我们,联系我们,帮助中心,无限广告，无限可能
    footer: {
        about: `关于我们`,
        contact: `联系我们`,
        help: `帮助中心`,
        service: `服务协议`,
        privacy: '隐私政策',
        delivery: `交付政策`,
        advertise: `无限广告，无限可能`,
    },
    product: {
        text_7: `产品介绍`,
        text_8: `10%-30% GMV增幅`,
        text_9: `Unlimited Adx拥有海量广告位及客户资源供商家向更多的潜在顾客充分展示广告从而获得额外销售额。 Unlimited Adx全球用户周覆盖数达120亿，每日拥有4500亿次广告展示机会，Unlimited Adx使用者通常可获得10%—30%的销售额增幅。`,
        text_10: `0成本接入CPS广告`,
        paragraph_1: `Unlimited Adx为智能广告投放系统，商家可以app实现零代码一键接入，用户完成广告开户后系统将自动创建广告并管理预算无需人工介入。`,
        paragraph_1_1: `开通Unlimited Adx 无需要支付开户费或订阅费等前期费用，Unlimited Adx采用CPS（按实际的销售金额支付广告费）计费，让用户实现广告费0浪费。`,
        paragraph_2: `"0" 成本接入CPS广告`,
        text_11: `GMV 增加10%-30%，ROI 2.5+`,
        text_12: `一键安装`,
        text_13: `为什么选择我们？`,
        text_14: `我们以高品质设计创造商业价值，以创新理念提升品牌影响力`,
        text_17: `收费模式`,
        text_18: `Unlimited Adx采用CPS（Cost Per Sales）模式收取费用，即按实际销售金额来支付广告费的后付费模式，除CPS广告费之外，无任何其他费用。`,
        lanhutext0_0_0: `零成本接入CPS`,
        lanhutext0_0_1: `Unlimited Adx采用cps收费模式，用户接入Unlimited Adx时无需支付开户费及服务费。当广告产生订单后用户可通过信用卡或saas平台代扣方式支付广告费。
        用户可以通过app实现Unlimited Adx 零代码接入各常用saas平台如（shopify,shoplazza,shopline 等），使用户无需进行网站代码修改。
        系统全程自动化完用户筛选、广告创建、广告预算调整等操作，广告投放全过程无需人工参与。`,
        lanhutext0_1_0: `有质量的ROI`,
        lanhutext0_1_1: `用户可以通过Unlimited Adx 获得2.5-4.5 ROI（销售金额/广告费），起步阶段系统默认roi为2.5。系统会周期性评估广告可实现的roi范围，用户可以根据系统评估结果选择自己的目标roi（调高目标roi会引起广告消耗下降）。
        Unlimited Adx 采用第三方统计数据（如google analytics 或saas系统渠道销量工具）作为计费基础，确保销售金额真实客观。`,
        lanhutext0_2_0: `一键接入点海量广告资源`,
        lanhutext0_2_1: `Unlimited Adx与合作伙伴拥有海量广告资源，周活跃用户数达120亿，每日多达4500亿次广告展示机会，能够触达全球超过90%的网民，让用户实现对潜在顾客全面覆盖。
        Unlimited Adx 的RTB（RealTime Bidding）技术瞬时完成对数百万媒体上出现的用户消费意愿分析并完成评估以及出价，因此Unlimited Adx可以瞬时触达全网潜在消费者。`,
        lanhutext0_3_0: `程序化广告系统`,
        lanhutext0_3_1: `Unlimited Adx，将多年积累的顾客唯一性特征、顾客分层画像、购买力及消费偏好等数据积淀与机器学习技术相融合，实现自动化广告系统。系统自动用户筛选、自动控制广告预算、广告自动调价、自动生成广告素材，真正做到零人工管理，用户只需按最终销售额支付广告费账单。`,
        lanhutext1_0_0: `销售时付款`,
        lanhutext1_0_1: `在这里，你只需在销售时付款，这是使用Unlimited Adx进行联盟计划的最大优势。加入是100%免费的，你也不会赔钱。您将为结果付出代价，只有当您这样做时，我们才能赚钱，一切都以您的利益为主。`,
        lanhutext1_1_0: `产品销售者`,
        lanhutext1_1_1: `代销商付款100%自动化，您不必担心税收或KYC的信息，退款也会自动处理。`,
        lanhutext1_2_0: `加入我们`,
        lanhutext1_2_1: `在这里，我保证您一定会按时拿到属于您的金钱，您不需要追逐供应商来获得报酬，在一个方便的地方拥有您所有的会员数据和统计数据。`,
    },
    price: {
        text_7: `收费模式`,
        paragraph_1: `Unlimited Adx采用CPS（Cost Per Sales）模式收取广告费，即产生实际销售金额后按质保ROI 来支付广告费（广告费=GMV/ROI）。除广告费之外，用户无任何其他费用。`,
        paragraph_1_1:`广告起步阶段质保ROI为2.5，随广告机器学习及数据匹配进度推进系统会自动展示当前可提供的ROI范围（2.5-4.5）。`,
        paragraph_1_2:`如何统计广告带来的GMV，用户可以通过Unlimited 账单页面查看本渠道带来订单明细，同时用户可以在平台UTM统计报表查询本渠道广告GMV，“unlimited_adx”为本渠道标识符。`,
        paragraph_2: `Unlimited  Adx完全符合:    我们采取非常积极的态度，确保Unlimited  Adx上列出的所有销售页面完全符合FTC。 \n我们专有的Unlimited  Adx支付以及与几个流行的支付网关的集成也意味着您将永远不会受到任意的商家账户规则的支配。  如果你无视欧盟严格的数据保护和隐私法，你将面临巨额罚款的风险。值得庆幸的是，我们的结账页面、选择加入流程、数据收集和存储完全符合GDPR标准，因此使用Unlimited  Adx建立一个有利可图的买家列表是没有风险和麻烦的。  \nUnlimited  Adx还完全`,
        text_10: `符合加拿大反垃圾邮件立法`,
        paragraph_3: `，确保添加到Unlimited  Adx或产品所有者列表中的所有买家和潜在客户都同意Unlimited  Adx和产品所有者未来的通信。  \nUnlimited  Adx`,
        text_11: `符合澳大利亚通信和媒体管理局的要求`,
        paragraph_4: `，确保添加到Unlimited  Adx或产品所有者名单中的所有买家和潜在客户都同意Unlimited  Adx和产品所有者未来的通信。\n  我们100%`,
        text_12: `符合支付卡行业的数据安全标准`,
        paragraph_5: `(PCI  DSS)，确保我们始终保护和加密您客户的所有信用卡数据。\n最好的部分是，你不必做任何事情，它适用于无论你使用我们的付款方式。  强客户身份认证(SCA)是欧盟的另一项严格规定，要求所有电子支付都要进行多重身份认证。当然你不用担心这个问题，因为这个功能已经内置到Unlimited  Adx和我们支持的支付处理器中了。`,
        text_13: `收费策略`,
        text_14: `Charging  strategy`,
        text_15: `除CPS广告费之外，无任何其他费用`,
        lanhutext0_0_0: `信用支付`,
        lanhutext0_0_1: `用户可通过visa、master、jcb信用卡支付广告费。Unlimited广告产生订单24小时后系统会通过信用卡进行收费。`,
        lanhutext0_1_0: `电商平台代收`,
        lanhutext0_1_1: `如果用户已经与电商平台建立付款协议，用户可通过电商平台支付广告（仅支持shopify & shoplaza）。Unlimited广告产生订单24小时后系统会通过电商平台向用户收取广告费。`,
        lanhutext0_2_0: `线下付款`,
        lanhutext0_2_1: `可通过银行转账的方式支付广告费，收款信息及折扣信息请联系您的客户经理。`,
        text_16: `付款方式`,
    },
    about: {
        text_13: `关于我们`,
        text_14: `About us`,
        text_15: `帮助更多的商家实现广告投放的简单高效`,
        text_7: `关于我们`,
        paragraph_1: `Unlimited Adx是由一群具有多年电商经验和大数据专业技术人才创立的，我们的CPS服务模式正是基于团队长期以来在电商和广告领域的沉淀而发生。\n通过Unlimited Adx，商家可以`,
        text_8: `一键接入全球的广告资源`,
        paragraph_2: `、触达全网超90%的用户，而且无需付出任何广告投放的人工成本和管理精力，只需要按最终的销售结果支付广告费用，使商家更专注于电商业务本身。并且，基于我们的经验，我们为商家提供了ROI最低保障为2.5，免去任何关于广告投放成本高的担忧。\n我们的目标是帮助更多的商家`,
        text_9: `实现广告投放的简单高效`,
        text_10: `，通过大数据和AI算法不断学习并`,
        text_11: `提升商家的ROI`,
        text_12: `。基于现有合作商家的数据，接入Unlimited Adx广告服务的商家平均比之前提升10%-30%的销售额，期待与更多的合作伙伴创造更加美好的未来。`,
        text_16: `我们的目标`,
        text_17: `01`,
        text_18: `为您完成营销活动`,
        text_19: `联系营销专家团队，帮助您实现目标并增加销售额。涵盖你的电子商务业务的所有重要领域，这样你就可以花更少的时间担心，并集中精力发展你的业务。`,
        text_23: `06`,
        text_24: `建立忠实的回头客基础`,
        text_25: `帮助您的客户回到废弃的购物车，并向他们发送个性化的优惠。收集电子邮件，毫不费力地扩大你的清单，设计能让你的客户一次又一次订购的电子邮件。`,
        text_26: `我们的产品推荐应用程序如何工作`,
        text_27: `问题是：98%的电子商务用户都不会买`,
        text_28: `想知道为什么只有不到2%的访问者最终购买了产品。实话实说:为你的网站带来流量已经变得极其昂贵。数字渠道的每次获取成本比以往任何时候都高，营销支出的投资回报率很难证明为什么你仍然在流量获取上投入资金。然而，有些品牌已经破解了电子商务转换和销售的密码。他们的秘密是:产品推荐测验。`,
        text_29: `解决方案：引导销售`,
        text_30: `购物是非常个人化的。每位顾客都是独一无二的，销售人员的参与是成功购物体验的关键。毕竟，销售人员提供的是免费咨询:你的客户不是专家，不知道哪种产品最适合他或她的需求。那么，当我们的客户在你的网站上寻找产品时，我们为什么要假设他们确切地知道他们需要什么呢？毕竟，网上购物者需要找到他们需要的东西的指导，就像在真实的商店里一样。`,
        text_31: `你的电子商店的个人购物者`,
        text_32: `如果你能在你的网站上给你的顾客提供个人购物体验的建议，会怎么样？这正是我们所做的。我们的应用通过产品推荐测验吸引您的客户，改善他们的产品发现体验，并引导他们从开始到购物车。`,
        text_33: `产品推荐测试是如何进行的`,
        text_34: `我们的应用程序像销售人员一样吸引你的客户。首先，会向您的客户询问一些一般性问题。一旦他回答了第一个问题，测验就会开始问更多针对他的具体问题。与此同时，该应用程序将为他提供个性化的反馈和提示。一旦测试完成，该应用程序会给出个性化的产品推荐，并讲述为什么推荐的产品对他来说是完美的。`,
        text_35: `根据您的特定需求定制测验`,
        text_36: `我们的应用程序包括样本测验模板，您可以用它来激发灵感，但我们知道您可能会想要编辑它，以符合您品牌的设计和传播指南。我们的应用程序允许您自定义测验的多个方面，使用我们易于使用的编辑器，您可以:  定制您提出的问题、个性化您给出的反馈、在多种字体和调色板中选择 向测验中添加背景图像。`,
        text_37: `联系我们`,
        text_38: `帮助您成功是我们的首要任务。这就是为什么我们的支持台一年365天由真人操作。您的业务永远不必等到周一早上，我们会在您最需要的时候为您提供帮助！`,
        text_39: `联系电话：+86 153-2168-5076`,
        text_40: `支持台客服：LiveChat`,
        text_41: `电子邮件：info@unlimitedadx.com`,
        text_42: `公司名称：ZHONGYA TRADING CO., LIMITED  中雅商貿有限公司`,
        text_43: `公司地址：RM 517 NEW CITY CENTRE 2 LEI YUE MUN ROAD KWUN TONG KL`,

        lanhutext1_0: `02`,
        lanhutext1_1: `从搜索引擎获得更多免费点击`,
        lanhutext1_2: `出现在搜索结果中是所有电子商务商店的必备条件。我们帮助您获得最多的点击，并为最重要的关键字排名更高，会将你的网站推到顶端。`,
        lanhutext2_0: `03`,
        lanhutext2_1: `将重复的电子商务任务转化为利润`,
        lanhutext2_2: `发送电子邮件、时事通讯和提醒可能是一个乏味的过程。我们的工具将自动化您的电子邮件，根据您的客户名单提供个性化和定制的电子邮件。`,
        lanhutext3_0: `04`,
        lanhutext3_1: `接触到想从你这里购买的用户`,
        lanhutext3_2: `我们的团队将制作横幅，撰写广告，并准备一个销售策略，将所有这些联系在一起，不浪费任何预算，会有一整个广告专家团队朝着你的目标努力。`,
        lanhutext4_0: `05`,
        lanhutext4_1: `一键提升您的电子商务商店`,
        lanhutext4_2: `你得到了一个专门的营销平台，收集电子邮件、触发弹出窗口，创建更好的时事通讯活动等等。我们的营销工具是专为配合您的商店而设计的`,
    },
    help: {
        text_28: `帮助中心`,
        text_29: `Help center`,
        text_30: `商家可以一键接入全球的广告资源`,
        text_3: `帮助中心`,
        text_4: `账户`,
        text_5: `店铺`,
        text_6: `广告`,
        text_7: `订单`,
        text_90: `如何登录Unlimited Adx`,
        text_91: `如何修改账户信息`,
        text_92: `如何修改机构/企业信息`,
        text_93: `如何管理子账户`,
        text_94: `如何添加新店铺`,
        text_95: `如何切换店铺`,
        text_96: `如何开通全球广告业务`,
        text_97: `如何调整ROI`,
        text_98: `如何查询广告订单`,
        text_99: `如何支付广告订单`,
    },
    help1: {
        text_21: `如何登录Unlimited Adx`,
        text_22: `01.`,
        text_23: `您可以在独立站应用市场内搜索Unlimited Adx App，通过应用内跳转登录到Unlimited Adx。`,
        text_24: `02.`,
        text_25: `您也可以访问Unlimited Adx Website站点 `,
        text_26: `https://myshoplaza.unlimitedadx.com`,
        text_27: `，通过账户和密码登录到Unlimited Adx。`,
    },
    help2: {
        text_21: `如何修改账户信息`,
        text_22: `01.`,
        text_23: `登录Unlimited Adx，在页面右上角【个人中心】中点击【用户设置】菜单进入用户设置页面。`,
        text_24: `02.`,
        text_25: `03.`,
        text_26: `您在用户设置页面可以维护用户名、密码、联系方式等信息，输入登录密码验证您的身份，点击【保存】按钮设置生效。`,
        text_27: `点击【X】关闭用户设置页面。`,
    },
    help3: {
        text_21: `如何修改机构/企业信息`,
        text_22: `01.`,
        text_23: `登录Unlimited Adx，在页面右上角【个人中心】中点击【机构设置】菜单进入机构设置页面。`,
        text_24: `02.`,
        text_25: `03.`,
        text_26: `您在机构设置页面可以维护企业/机构地址、联系人、联系方式等信息，方便我们更好的为您提供服务。输入登录密码验证您的身份，点击【保存】按钮使设置生效。`,
        text_27: `点击【X】关闭机构设置页面。`,
    },
    help4: {
        text_22: `如何管理子账号`,
        text_23: `01.`,
        text_24: `登录Unlimited Adx，在页面右上角【个人中心】中点击【账号管理】菜单，或者点击顶部菜单栏中的【子账号】菜单进入子账号管理页面。`,
        text_25: `02.`,
        text_26: `您在账号管理页面可以查看到您账号下的所有子账号信息，点击【添加账号】按钮可为您添加新的子账号。`,
        text_27: `03.`,
        text_28: `您可以添加“管理员”或者“子账号”两种类型的账号，其中“子账号”需要为其选择允许管理的店铺，“管理员”则拥有店铺权限。`,
        text_29: `04.`,
        text_30: `关于账号的各种权限可参考下图。`,
        text_31: `05.`,
        text_32: `创建账号时需要验证账号绑定的邮箱是否有效，输入邮箱地址，点击【发送验证码】后，账号对应的邮箱将收到一封添加子账号的通知邮件，并附带邮箱验证码。`,
        text_33: `06.`,
        text_34: `点击子账号的“账号权限”可查看当前子账号所关联的店铺信息。`,
    },
    // help5: {
    //     text_21: `如何添加新店铺`,
    //     text_22: `01.`,
    //     text_23: `您可以通过页面右上角【添加新店铺】为自己的账号添加新的店铺。`,
    //     text_24: `02.`,
    //     text_25: `点击【访问Shoplazza应用市场】按钮将跳转到店匠应用市场，并打开Unlimited Adx App安装页面。订阅成功后，相同账号的Shoplazza店铺将在Unlimited Adx App中自动链接到一起。`
    // },
    help5: {
        text_21: `如何添加新店铺`,
        text_22: `Shoplazza`,
        text_23: `您可以通过页面右上角【添加店铺】为自己的账号增加新的店铺。`,
        text_24: `点击【访问Shoplazza应用市场】跳转到店匠应用市场，并打开Unlimited Adx应用安装页面。安装成功后，相同账号的店铺将在Unlimited Adx应用中自动链接到一起。`,
        text_25: `Shopline`,
        text_26: `您可以通过页面右上角【添加店铺】为自己的账号增加新的店铺。`,
        text_27: `点击【访问Shopline应用市场】跳转到Shopline应用市场，并打开Unlimited Adx应用安装页面。安装成功后，相同账号的店铺将在Unlimited Adx应用中自动链接到一起。`,
        text_28: `Shopify`,
        text_29: `您可以通过页面右上角【添加店铺】为自己的账号增加新的店铺。`,
        text_30: `点击【访问Shopify应用市场】跳转到Shopify应用市场，并打开Unlimited Adx应用安装页面。安装成功后，相同账号的店铺将在Unlimited Adx应用中自动链接到一起。`,
        text_31: `01.`,
        text_32: `02.`
    },
    help6: {
        text_22: `如何切换店铺`,
        text_23: `01.`,
        text_24: `如果您的账号下有多个店铺订阅了Unlimited Adx App，可以通过页面右上角的店铺列表切换店铺，并查看不同店铺的对账单信息。`
    },
    help7: {
        text_21: `如何开通全球广告业务`,
        text_22: `01.`,
        text_23: `首次登录Unlimited Adx App，需要您点击【申请开通全球广告投放】按钮一键申请开通广告业务。`,
        text_24: `02.`,
        text_25: `03.`,
        text_26: `04.`,
        paragraph_1: `我们将会为您的店铺注册广告账户，并创建广告所需的商品信息。\n（1）系统正在收集商品信息，用于创建广告\n（2）广告创建结束后将进行广告审核\n（3）上述过程预计在24-48小时内完成`,
        paragraph_2: `我们将会通过机器学习，逐步优化您的店铺广告投放。\n（1）系统正在进行机器学习\n（2）系统会依据收集的数据调整广告出价及预算\n（3）系统会根据广告表现调整受众人群标签\n（4）此过程预计耗时14-28天`,
        text_27: `当所有步骤执行完成，则表示登录Unlimited Adx已经为您的店铺正式开通了广告投放，现在，您可以点击【完成申请，查看对账单】来了解Unlimited Adx为您带来的广告效益。`,
        text_28: `05.`,
        text_29: `后续我们将会逐步开放保障ROI水平设置，您可以自由调整ROI水平进行广告投放。`
    },
    help8: {
        text_21: `如何查询广告订单`,
        text_22: `01.`,
        text_23: `您可以点击【对帐单】菜单查看Unlimited Adx为您投放广告而产生的交易订单。`,
        text_24: `02.`,
        text_25: `我们提供了订单查询与筛选功能，您可以通过搜索框和筛选器进行订单查询。通过“结算状态”以及“时间范围”来筛选出您想要查看的订单信息，当然也提供了便捷的时间段选择块。`
    },


}



    
