import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
import zh from './zh.js'
import en from './en.js'
Vue.use(VueI18n)
const messages = {
  zh,
  en
}
let navLang = navigator.language || navigator.userLanguage;
if(navLang.indexOf('en') > -1){
  navLang = 'en'
}else{
  navLang = 'zh'
}
let lang = localStorage.getItem('language') || navLang;

const i18n = new VueI18n({
  messages,
  // locale: 'zh'
  locale: lang
})
export default i18n