import '@/style/theme/index.css'
import Vue from 'vue'
import App from './App.vue'

import "animate.css" //动画
import Navigation from 'vue-navigation' //前进刷新，后退不刷新依赖,类似于keep-alive
import router from '@/router'
import ElementUI, { Message } from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';

import i18n from '@/i18n'

Vue.use(ElementUI)
Vue.use(Navigation, {router, store: null, moduleName: 'navigation', keyName: 'VNK'})


Vue.config.productionTip = false

// 防止频繁点击
Vue.prototype.debounce = (func, wait) => {
  let timeout = '';
  return (v) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(v);
    }, wait);
  }
};

// 错误弹框
Vue.prototype.$error = (()=>{
  Message({
    type:"warning",
    message:"网络连接失败,请重试或联系管理员",
    duration:2000
  })
})

new Vue({
  i18n,
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$eventBus = this
  }
}).$mount('#app')
