import Vue from 'vue'
import Router from 'vue-router'
import pcRoutes from './pc'
import mobRoutes from './mob'
import platform from '@/utils/getPlatform'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false });

Vue.use(Router)

export const constantRoutes = [
  pcRoutes,
  mobRoutes,
  ,
    {
      path: '*',
      redirect: '/home',
    } 
]



// 解决路由跳转报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
return originalPush.call(this, location).catch(err => err)
}

Router.prototype.replace = function replace (location) {
  return originalPush.call(this, location).catch(err => err)
  }

const createRouter = () => new Router({
  // mode: 'history', // require service support
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    //滚动具体偏移
    console.log('to.query',to.query);
    if (to.query.yOffset !== undefined) {
      console.log('to.query.yOffset',to.query.yOffset);
      return {x: 0, y:to.query.yOffset}
    }
    // if(to.path.indexOf('/help') == 0) { //help页面不滚动
    //   return false
    // }
    return false;
    // return { x: 0, y: 0}
  },
  routes: constantRoutes
})

const router = createRouter()
router.beforeEach((to, from, next) => {
  // start progress bar
  NProgress.start()
  //如果不是电脑, to.path前加上/mob
  const p = platform();
  console.log('route',p.isPc, to.path);
  if(!p.isPc && to.path.indexOf('/mob') != 0) {
    next('/mob'+to.path);
    return;
  }
  if (p.isPc && to.path.indexOf('/mob') == 0) {
    next(to.path.replace('/mob',''));
    return;
  }
    
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
