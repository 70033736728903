<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { startsWith } from 'lodash-es';
import getPlatform from './utils/getPlatform';
export default {
  name: 'App',
  data() {
    return {
    }
  },
  methods: {
    onOnlineService() {
      const online = decodeURI(process.env.VUE_APP_ONLINE_SCRIPT);
      console.log('online', online);
      if (startsWith(online, '<script')) {
        const div = document.createElement('div');
        div.innerHTML = online;
        const sc = div.childNodes[0];
        let url = '';
        if (startsWith(sc.src, 'http')) {
          url = sc.src;
        } else {
          url = 'http:' + sc.src;
        }
        const HEAD = document.getElementsByTagName('head')[0];
        const scriptTag = document.createElement('script');
        scriptTag.src = url;
        scriptTag.async = true;
        console.log('scriptTag', scriptTag);
        HEAD.appendChild(scriptTag);
      }
    },
  },
  mounted() {
    this.onOnlineService();
    window.onresize = () => {
      // this.$store.commit('SET_WINDOW_SIZE', {
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      // });
      let p = getPlatform();
      console.log('window.innerWidth', window.innerWidth, p.isPc);
      //改为手机路径
      if (window.innerWidth < 768 && !p.isPc && this.$route.path.indexOf('/mob') != 0) {
        window.location.reload(true);
      } else if (window.innerWidth > 768 && p.isPc && this.$route.path.indexOf('/mob') == 0) {
        window.location.reload(true);
      }
    };
  }
}
</script>

<style>
@import '@/common.css';

#app {
  font-family: SourceHanSansSC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* overflow-y: hidden;
  overflow-x: auto; */
}

#nprogress .bar {
  background: #347AFC !important;
}
</style>
