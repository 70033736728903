export default 
    {
      path: '/mob',
      component: () => import('@/views/mobile/main'),
      name: 'Main',
      redirect: {name:'MobHome'},
      meta: { title: '首页' },
      children:[
        {
          path: 'home',
          component: () => import('@/views/mobile/home/home'),
          name: 'MobHome',
          meta: { title: '产品', parentName: '/home' }
        },
        {
          path: 'price',
          component: () => import('@/views/mobile/price/price'),
          name: 'MobPrice',
          meta: { title: '定价', parentName: '/price' }
        },
        {
          path: 'about',
          component: () => import('@/views/mobile/about/about'),
          name: 'MobAbout',
          meta: { title: '我们', parentName: '/about' }
        },
        {
          path: 'service',
          component: () => import('@/views/mobile/service/service'),
          name: 'MobService',
          meta: { title: '服务协议', parentName: '' }
        },
        {
          path: 'privacy',
          component: () => import('@/views/mobile/privacy/privacy'),
          name: 'MobPrivacy',
          meta: { title: '隐私政策', parentName: '' }
        },
        {
          path: 'delivery',
          component: () => import('@/views/mobile/delivery/delivery'),
          name: 'MobDelivery',
          meta: { title: '交付政策', parentName: '' }
        },
        {
          path: 'help',
          component: () => import('@/views/mobile/help/help'),
          redirect: '/help/help1',
          name: 'MobHelp',
          meta: { title: '帮助', parentName: '/help' },
          children:[
            {
              path: 'help1',
              component: () => import('@/views/mobile/help/help1'),
              name: 'MobHelp1',
              meta: {  parentName: '/help', index: 'help1', menu: 'account' }
            },
            {
              path: 'help2',
              component: () => import('@/views/mobile/help/help2'),
              name: 'MobHelp2',
              meta: {  parentName: '/help', index: 'help2', menu: 'account' }
            },
            {
              path: 'help3',
              component: () => import('@/views/mobile/help/help3'),
              name: 'MobHelp3',
              meta: {  parentName: '/help', index: 'help3', menu: 'account' }
            },
            {
              path: 'help4',
              component: () => import('@/views/mobile/help/help4'),
              name: 'MobHelp4',
              meta: {  parentName: '/help', index: 'help4', menu: 'account' }
            },
            {
              path: 'help5',
              component: () => import('@/views/mobile/help/help5'),
              name: 'MobHelp5',
              meta: {  parentName: '/help', index: 'help5', menu: 'shop' }
            },
            {
              path: 'help6',
              component: () => import('@/views/mobile/help/help6'),
              name: 'MobHelp6',
              meta: {  parentName: '/help', index: 'help6', menu: 'shop' }
            },
            {
              path: 'help7',
              component: () => import('@/views/mobile/help/help7'),
              name: 'MobHelp7',
              meta: {  parentName: '/help', index: 'help7', menu: 'ad' }
            },
            {
              path: 'help8',
              component: () => import('@/views/mobile/help/help8'),
              name: 'MobHelp8',
              meta: {  parentName: '/help', index: 'help8', menu: 'order' }
            },
  
          ]
        },
        
      ]
    }