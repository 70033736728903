export  default {
    title: `English`,
    header: {
        product: `Product`,
        price: `Price`,
        about: `About`,
        help: `Help`,
        language: `Language`,
        zh: `中文`,
        en: `English`,
        login: `Login`,
        changeLanguage: `Change Language`,
    },
    footer: {
        about: `About us`,
        contact: `Contacst us`,
        help: `Help center`,
        advertise: `Unlimited advertising, unlimited possibilities`,
    },
    product: {
        text_7: `Product Introduction`,
        text_8: `Increase merchant sales`,
        text_9: `With Unlimited Adx advertising solutions, you can typically increase sales for independent merchants by 10% to 30% without the day-to-day operational and administrative effort of the merchant, simply paying for the AD based on the final dollar amount sold. `,
        text_10: `Provide CPS advertising services`,
        paragraph_1: `Unlimited Adx is a smart AD delivery system that allows merchants to access the app with zero code and one click. After users open an AD account, the system will automatically create ads and manage budgets without human intervention.`,
        paragraph_1_1: `There is no need to pay upfront fees such as account opening fees or subscription fees to open Unlimited Adx. Unlimited Adx uses CPS (advertising fees paid according to the actual sales amount) billing, allowing users to realize zero waste of advertising fees.`,
        paragraph_2: `Unlimited Adx`,
        text_11: `Unlimited Possibilities`,
        text_12: `One click install`,
        text_13: `Why us? `,
        text_14: `We create commercial value with high quality design, enhance brand influence with innovative ideas`,
        text_17: `Charge mode`,
        text_18: `Unlimited Adx is charged on the CPS (Cost Per Sales) model, which is a postpaid model where advertising is paid according to the actual sales amount. There are no other fees except CPS advertising fees. `,
        lanhutext0_0_0: `CPS relief service`,
        lanhutext0_0_1: `Unlimited Adx offers the most hasslefree service at zero cost for merchants with one-click access. The system automatically completes data collation, model training, material creativity, advertising, effect optimization, and truly realizes zero labor, zero participation and zero management. Merchants only need to pay advertising fees according to the final sales bill. `,
        lanhutext0_1_0: `ROL effective guarantee`,
        lanhutext0_1_1: `To provide merchants with ROL guarantee service, we rely on advanced machine learning technology, rich advertising resources, massive user data, can achieve 2.0-3.5 ROL guarantee level. After the system evaluation, the merchants only need to pay the advertising fee at the ROL level they set themselves. For example, when guaranteed ROL is set to 2.5, an Unlimited Adx AD cost of $80 generates $100 in sales for the merchant, and the merchant only has to pay $40 ($100 /2.5) for the AD. `,
        lanhutext0_2_0: `Global Advertising Resources`,
        lanhutext0_2_1: `Unlimited Adx and partners have access to global advertising resources, advertising on 3 million + media, can reach more than 90% of the world's Internet users, and can provide advertisers with 140 billion + displays per day. With Unlimited Adx, you can easily connect to consumers all over the world and reach them all the time. `,
        lanhutext0_3_0: `Autonomous Machine Learning`,
        lanhutext0_3_1: `Unlimited Adx adopts advanced machine learning algorithm, combined with years of accumulated data and e-commerce experience, and has accumulated excellent solutions in unique consumer identification and hierarchical crowd portrait. Relying on big data and machine learning, it can continuously and automatically optimize user selection, advertising budget, quotation strategy, material display and other aspects. Continue to increase the return on advertising investment for businesses. `,
        lanhutext1_0_0: `Pay on sale`,
        lanhutext1_0_1: `Here, you only pay at the time of sale, which is the biggest advantage of affiliate planning with Unlimited Adx. Joining is 100% free and you won't lose money. You will pay the price for the result, and only when you do, can we make money. Everything is in your interest. `,
        lanhutext1_1_0: `Product seller`,
        lanhutext1_1_1: `Affiliate payment is 100% automated, you don't have to worry about tax or KYC information, refunds will also be processed automatically. `,
        lanhutext1_2_0: `Join us`,
        lanhutext1_2_1: `Here, I guarantee you will get your money on time, you don't need to chase vendors to get paid, and have all your membership data and statistics in one convenient place. `,
        },
    price: {
        text_7: `Charge mode `,
        paragraph_1: `Unlimited Adx adopts the CPS (Cost Per Sales) model to charge advertising fees, that is, after generating international sales amount, advertising fees are paid according to the warranty ROI (advertising fees =GMV/ROI). In addition to advertising fees, the user does not have any other costs.`,
        paragraph_1_1:`The guaranteed ROI in the initial stage of advertising is 2.5, and with the progress of advertising machine learning and data matching, the system will automatically show the current ROI range (2.5-4.5).`,
        paragraph_1_2:`How to count the GMV brought by advertisements, users can view the order details brought by this channel through the Unlimited billing page. At the same time, users can query the channel advertising GMV in the saas platform UTM statistical report. "unlimited_adx" is the channel identifier.`,
        paragraph_2: `Unlimited Adx fully meets: We take a very positive approach to ensuring that all sales pages listed on Unlimited Adx fully comply with the FTC. \n Our proprietary Unlimited Adx payments and integration with several popular payment gateways also means you will never be at the mercy of arbitrary merchant account rules. If you flout the EU\'s strict data protection and privacy laws, you risk hefty fines. Thankfully, our checkout page, opt-in process, data collection and storage are fully GDPR compliant, so building a list of profitable buyers using Unlimited Adx is risk-free and hassle-free. \nUnlimited Adx is also full `,
        text_10: `Comply with Canadian Anti-spam Legislation`,
        paragraph_3:`, ensuring that all buyers and potential customers added to Unlimited Adx or the product owner list agree to future communications between Unlimited Adx and the product owner. \nUnlimited  Adx `,
        text_11: `Meet the requirements of the Australian Communications and Media Authority`,
        paragraph_4: `, ensuring that all buyers and potential customers added to Unlimited Adx or the product owner\'s list agree to future communications between Unlimited Adx and the product owner. \n we 100% prime `,
        text_12: `Compliance with payment card industry data security standards `,
        paragraph_5: `(PCI DSS), ensuring that we always protect and encrypt all of your customers\' credit card data. \n The best part is that you don\'t have to do anything and it applies regardless of whether you use our payment method. Strong Customer Identity Authentication (SCA) is another strict EU regulation that requires multiple identifications for all electronic payments. Of course you don\'t have to worry about that, as this feature is already built into Unlimited Adx and the payment processors we support. `,
        text_13: `Charging strategy`,
        text_14: `Charging  strategy`,
        text_15: `No fees other than CPS advertising fees `,
        lanhutext0_0_0: `Pay on sale `,
        lanhutext0_0_1:
        `Here, you just pay at the time of sale, which is the biggest advantage of affiliate planning with Unlimited Adx. Joining is 100% free and you won\'t lose money. You will pay the price for the result, and only when you do, can we make money. Everything is in your interest. `,
        lanhutext0_1_0: `Product seller `,
        lanhutext0_1_1:
        `Affiliate payments are 100% automated, you don\'t have to worry about taxes or KYC information, and refunds are processed automatically. `,
        lanhutext0_2_0: `Join us `,
        lanhutext0_2_1:
        `Here, I guarantee you'll get your money on time, you won't have to chase vendors to get paid, and you\'ll have all your membership data and statistics in one convenient place. `,
        text_16: `Terms of payment`,
        },

    about: {
        text_13: `About us`,
        text_14: `About us`,
        text_15: `To help more merchants achieve simple and efficient advertising`,
        text_7: `About us`,
        paragraph_1: `Unlimited Adx is founded by a group of people with many years of e-commerce experience and big data expertise. Our CPS service model happens based on what our team terms Paragraph_1 and advertising. \n With Unlimited Adx, merchants can `,
        text_8: `One-click access to global advertising resources`,
        paragraph_2: `, reaches more than 90% of the whole network's users, and without paying any labor costs or management effort for advertising, it only pays for advertising by the final sales result. It makes merchants focus more on e-commerce business itself. And, based on our experience, we offer merchants a minimum guarantee for ROL [2.0-3.5] to remove any concerns about high advertising costs. \n Our goal is to help more businesses `,
        text_9: `Achieve simple and efficient advertising`,
        text_10: `, constantly learning through big data and AI algorithms and `,
        text_11: `Improve the merchant's ROL`,
        text_12: `. Based on data from existing merchants, merchants who sign up for Unlimited Adx are seeing an average 10 percent to 30 percent increase in sales over the past few years, and are looking forward to a brighter future with more partners. `,
        text_16: `Our goal`,
        text_17: `01`,
        text_18: `Complete marketing campaign for you`,
        text_19: `Connect with a team of marketing experts to help you reach your goals and increase sales. Cover all the important areas of your e-commerce business so that you can spend less time worrying and concentrate on growing your business. `,
        text_23: `06`,
        text_24: `Build a loyal base of repeat customers`,
        text_25: `Help your customers return to abandoned shopping carts and send them personalized offers. Collect emails, effortlessly expand your list, and design emails that will get your customers to order again and again. `,
        text_26: `Our product recommends how the application works`,
        text_27: `The problem is: 98% of e-commerce users won't buy it`,
        text_28: `Wondering why less than 2% of visitors end up buying the product. Let's be honest: driving traffic to your website has become extremely expensive. The cost per acquisition of digital channels is higher than ever, and ROI on marketing spending makes it hard to justify why you're still spending money on traffic acquisition. However, some brands have cracked the code for e-commerce conversion and sales. Their secret: product recommendation tests. `,
        text_29: `Solution: Lead sales`,
        text_30: `Shopping is very personal. Every customer is unique, and salesperson involvement is key to a successful shopping experience. After all, the salesperson is offering free consultation: your customer is not an expert on which product will best suit his or her needs. So why should we assume that our customers know exactly what they need when they look for products on your site? After all, online shoppers need guidance on finding what they need, just as much as in a real store. `,
        text_31: `The personal Shopper in your electronics store`,
        text_32: `What if you could offer your customers advice on their personal shopping experience on your website? And that's exactly what we did. Our app engages your customers with product recommendation quizzes, improves their product discovery experience, and guides them from start to cart. `,
        text_33: `How the product recommendation test is conducted`,
        text_34: `Our app appeals to your customers like a salesperson. First, ask your customers some general questions. Once he answers the first question, the quiz starts asking more questions specific to him. At the same time, the app will provide him with personalized feedback and tips. Once the test is complete, the app makes a personalized product recommendation and tells why the recommended product is perfect for him. `,
        text_35: `Tailor the test to your specific needs`,
        text_36: `Our app includes sample quiz templates that you can use for inspiration, but we know you may want to edit it to fit your brand's design and communication guidelines. Our application allows you to customize many aspects of the quiz. Using our easy-to-use editor, you can: customize the questions you ask, personalize the feedback you give, and choose from a variety of fonts and palettes to add background images to the quiz. `,
        text_37: `Contact us`,
        text_38: `Helping you succeed is our top priority. That's why our support desk is manned by real people 365 days a year. Your business never has to wait until Monday morning, we are here to help you when you need it most! `,
        text_39: `Contact number: +86 153-2168-5076`,//152-0152-8531
        text_40: `Support desk: LiveChat`,
        text_41: `Email: info@unlimitedadx.com`,
        
        lanhutext1_0: `02`,
        lanhutext1_1: `Get more free clicks from search engines`,
        lanhutext1_2: `Appearing in search results is a requirement for all e-commerce stores. We help you get the most clicks and rank higher for the most important keywords that will push your site to the top. `,
        lanhutext2_0: `03`,
        lanhutext2_1: `Turn repetitive e-business tasks into profits`,
        lanhutext2_2: `Sending emails, newsletters, and reminders can be a tedious process. Our tool will automate your email, providing personalized and customized emails based on your customer list. `,
        lanhutext3_0: `04`,
        lanhutext3_1: `Reach users who want to buy from you`,
        lanhutext3_2: `Our team will make banners, write ads, and prepare a sales strategy, tie it all together, without wasting any budget, there will be a whole team of advertising experts working towards your goals. `,
        lanhutext4_0: `05`,
        lanhutext4_1: `Boost your e-commerce Store with One Click`,
        lanhutext4_2: `You get a dedicated marketing platform to collect emails, trigger pop-ups, create better newsletter campaigns, etc. Our marketing tools are designed to work with your store`,
        },
        help: {
            text_28: `Help Center`,
            text_29: `Help center`,
            text_30: `Merchants can access global advertising resources with one click`,
            text_3: `Help Center`,
            text_4: `account`,
            text_5: `shop`,
            text_6: `Advertising`,
            text_7: `order`,
            text_90: `How to Log into Unlimited Adx`,
            text_91: `How to Modify Account Information`,
            text_92: `How to Modify Organization/Enterprise Information`,
            text_93: `How to Manage Subaccounts`,
            text_94: `How to Add a New Store`,
            text_95: `How to Switch Shops`,
            text_96: `How to Start a Global Advertising Business`,
            text_97: `How to adjust ROL`,
            text_98: `How to Query Advertising Orders`,
            text_99: `How to pay for Advertising orders`,
            },
            help1: {
                text_21: `How to Log into Unlimited Adx`,
                text_22: `01.`,
                text_23: `You can search for the Unlimited Adx App in the Standalone App Marketplace and log in to Unlimited Adx via in-app Jump. `,
                text_24: `02.`,
                text_25: `You can also visit the Unlimited Adx Website `,
                text_26: `https://myshoplaza.unlimitedadx.com`,
                text_27: `, login to Unlimited Adx with an account and password. `,
                },
                help2: {
                text_21: `How to modify Account Information`,
                text_22: `01.`,
                text_23: `Log in to Unlimited Adx and click the User Settings menu in Personal Center at the upper right corner of the page to access the User Settings page. `,
                text_24: `02.`,
                text_25: `03.`,
                text_26: `You can maintain the user name, password, contact information and other information on the user setting page. Input the login password to verify your identity. Click the "Save" button to make the setting take effect. `,
                text_27: `Click [X] to close the user Settings page. `,
                },
                help3: {
                text_21: `How to modify Organization/Enterprise Information`,
                text_22: `01.`,
                text_23: `Log in to Unlimited Adx and click the Organization Settings menu in Personal Center at the upper right corner of the page to access the Organization Settings page. `,
                text_24: `02.`,
                text_25: `03.`,
                text_26: `You can maintain the company/organization address, contact person, contact information and other information on the organization setting page, so that we can provide better services for you. Enter your login password to verify your identity and click the "Save" button to make the Settings take effect. `,
                text_27: `Click [X] to close the mechanism Settings page. `,
                },
                help4: {
                text_22: `How to manage subaccounts`,
                text_23: `01.`,
                text_24: `Log in to Unlimited Adx and click the "Account Management" menu in the "Personal Center" at the upper right corner of the page, or click the "Subaccount" menu in the top menu bar to access the Subaccount management page. `,
                text_25: `02.`,
                text_26: `You can view the information of all sub-accounts under your account in the account management page. Click the "Add Account" button to add a new sub-account for you. `,
                text_27: `03.`,
                text_28: `You can add an "administrator" or a "sub-account", where the "sub-account" needs to select the allowed store for it, and the "administrator" has the store rights. `,
                text_29: `04.`,
                text_30: `See the following figure for the various permissions of the account. `,
                text_31: `05.`,
                text_32: `When creating an account, you need to verify whether the email box bound to the account is valid. Enter the email address and click [Send Verification code]. The email box corresponding to the account will receive a notification email for adding a sub-account, with the email verification code attached. `,
                text_33: `06.`,
                text_34: `Click the "Account Permission" of the subaccount to view the store information associated with the current subaccount. `,
                },
                help5: {
                text_21: `How to Add a New Store`,
                text_22: `01.`,
                text_23: `You can add a new store to your account via Add New Store at the top right corner of the page. `,
                text_24: `02.`,
                text_25: `Clicking the Visit Shoplazza App Market button will take you to the Shopmaker App Market and open the Unlimited Adx App installation page. Once subscribed, Shoplazza stores with the same account will automatically be linked together in the Unlimited Adx App. `
                },
                help6: {
                text_22: `How to switch shops`,
                text_23: `01.`,
                text_24: `If you have multiple stores subscribed to the Unlimited Adx App under your account, you can switch stores through the store list in the upper right corner of the page and view the various store statements. `
                },
                help7: {
                text_21: `How to Start a Global Advertising Business`,
                text_22: `01.`,
                text_23: `To log in to Unlimited Adx App for the first time, you need to click the "Apply for Global Advertising" button to apply for advertising service with one click. `,
                text_24: `02.`,
                text_25: `03.`,
                text_26: `04.`,
                paragraph_1: `We will sign up for an advertising account for your shop and create the information about the goods it needs to advertise. \n (1) The system is collecting information about the goods used to create the advertisement \n (2) The advertisement will be reviewed after the advertisement is created \n (3) The above process is expected to be completed within 24-48 days`,
                paragraph_2: `We step by step to improve your store's advertising Paragraph_2 through machine learning. \n (1) system is carrying out machine learning \n (2) system will adjust the advertising bid and budget according to the collected data \n (3) system will adjust the audience label \n (4) according to the advertising performance \n (4) This process is expected to take 14-28 days`,
                text_27: `When all steps are completed, signing up for Unlimited Adx has officially opened up advertising for your store. Now you can click [Complete Application, view statement] to learn more about the advertising benefits Unlimited Adx can bring to you. `,
                text_28: `05.`,
                text_29: `In the future, we will gradually open the guaranteed ROL level Settings. You are free to adjust the ROL level for advertising. `
                },
                help8: {
                text_21: `How to Query Advertising Orders`,
                text_22: `01.`,
                text_23: `You can click on the Billing menu to view the transaction orders that Unlimited Adx has placed for you. `,
                text_24: `02.`,
                text_25: `We provide order query and filter function, you can search through the search box and filter for order query. Select the order information you want to view through the "settlement status" and "time range", of course, also provides a convenient time period selection block. `
                },
}  