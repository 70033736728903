export default 
    // {
    //   path: '/login',
    //   component: () => import('@/views/login/index'),
    //   hidden: true
    // },
    {
      path: '/',
      components:{
        default: () => import('@/views/main'),
        pc: () => import('@/views/main'),
        mob: () => import('@/views/mobile/main'),
      },
      name: 'Main',
      redirect:  {name:'Home'},
      meta: { title: '首页' },
      children:[
        {
          path: 'home',
          component: () => import('@/views/home/index'),
          name: 'Home',
          meta: { title: '产品', parentName: '/home' }
        },
        {
          path: 'price',
          component: () => import('@/views/price/price'),
          name: 'Price',
          meta: { title: '定价', parentName: '/price' }
        },
        {
          path: 'about',
          component: () => import('@/views/about/about'),
          name: 'About',
          meta: { title: '我们', parentName: '/about' }
        },
        {
          path: 'service',
          component: () => import('@/views/service/service'),
          name: 'Service',
          meta: { title: '我们', parentName: '/about' }
        },
        {
          path: 'privacy',
          component: () => import('@/views/privacy/privacy'),
          name: 'Privacy',
          meta: { title: '我们', parentName: '/about' }
        },
        {
          path: 'delivery',
          component: () => import('@/views/delivery/delivery'),
          name: 'Delivery',
          meta: { title: '我们', parentName: '/about' }
        },
        {
          path: 'help',
          component: () => import('@/views/help/help'),
          redirect: '/help/help1',
          name: 'Help',
          meta: { title: '帮助', parentName: '/help' },
          children:[
            {
              path: 'help1',
              component: () => import('@/views/help/help1'),
              name: 'help1',
              meta: {  parentName: '/help', index: 'help1' }
            },
            {
              path: 'help2',
              component: () => import('@/views/help/help2'),
              name: 'help2',
              meta: {  parentName: '/help', index: 'help2' }
            },
            {
              path: 'help3',
              component: () => import('@/views/help/help3'),
              name: 'help3',
              meta: {  parentName: '/help', index: 'help3' }
            },
            {
              path: 'help4',
              component: () => import('@/views/help/help4'),
              name: 'help4',
              meta: {  parentName: '/help', index: 'help4' }
            },
            {
              path: 'help5',
              component: () => import('@/views/help/help5'),
              name: 'help5',
              meta: {  parentName: '/help', index: 'help5' }
            },
            {
              path: 'help6',
              component: () => import('@/views/help/help6'),
              name: 'help6',
              meta: {  parentName: '/help', index: 'help6' }
            },
            {
              path: 'help7',
              component: () => import('@/views/help/help7'),
              name: 'help7',
              meta: {  parentName: '/help', index: 'help7' }
            },
            {
              path: 'help8',
              component: () => import('@/views/help/help8'),
              name: 'help8',
              meta: {  parentName: '/help', index: 'help8' }
            },
  
          ]
        },
        
      ]
    }